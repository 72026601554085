@import '../../../styles/constants.scss';

@media only screen and (max-width: 1200px) {
  .header {
    img {
      max-height: 1.5em;
    }

    .header-container {
      height: auto !important;
      .header-title {
        a {
          font-size: 10px;
        }
      }

      a {
        font-size: 9px;
      }
    }
  }
}

.header {
  display: flex;
  background-color: $pure-black;
  color: white;
  height: auto;
  overflow: show;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  // padding: 0 15px;

  .header-container {
    display: flex;
    flex-shrink: 0;
    height: 100%;
    flex-wrap: wrap;
    flex: 1;

    // display: flex;
    background-color: $pure-black;
    color: white;
    // height: auto;
    // overflow: show;
    flex-direction: row;
    flex-shrink: 0;
    // align-items: center;
    // justify-content: space-between;
    // flex-wrap: wrap;

    // align-items: center;
    // justify-content: center;
  }

  .header-link {
    height: 100%;
  }

  .popover-navbar {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding: 10px 20px;
    align-items: center;
    flex-wrap: wrap;

    .popover-navbar-title {
      font-family: 'TJ Evolette A Basic', 'century-gothic';
      font-weight: 900;
      font-size: 25px;
      text-transform: uppercase;
    }

    .popover-navbar-subtitle {
      margin-left: 20px;
      flex-grow: 1;
      font-size: 24px;
    }
  }

  .header-title {
    // flex-grow: 1;
    display: flex;
    align-items: center;
    // height: fit-content;

    img {
      // margin-left: 1em;
      margin: 10px;
      margin-right: 1em;
      max-height: 2.1em;
    }

    a {
      display: flex;
      align-items: center;
      color: white;
      font-size: 20px;
      font-weight: 100;
      font-weight: 1 00;
      padding: 0;
      height: 100%;
      padding-right: 10px;
      // padding: 10px;
      // padding: 15px 16px;
    }
  }

  a:hover {
    text-decoration: none;
    color: white;
    cursor: pointer;
    background-color: $gray;
  }

  a {
    height: auto;
    display: flex;
    align-items: center;
    padding: 15px 30px;
    font-weight: 1000;
    font-size: 14px;
    justify-content: center;
    color: white;
  }

  a.header-active {
    background-color: $gray;
  }
}

.leave-btn {
  padding: 5px 16px;
  display: flex;
  align-items: center;

  a {
    border: 1px solid white;
    max-height: 3em;
    border-radius: 7px;
    padding: 6px 15px;
  }
}

.group-header {
  display: flex;
  background-color: $gray;
  color: white;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  flex-shrink: 0;

  a:hover {
    cursor: pointer;
    background-color: $pure-black;
  }

  .group-header-name {
    padding: 10px;
    display: flex;
    align-items: center;

    span {
      margin-left: 10px;
    }
  }
}

.sq-btn-container {
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  color: white;
  // padding: 20px;
  // padding-left: 20px;
  text-align: center;
  // height: 100%;

  .btn-square {
    height: 100%;
    width: 100%;
    padding: 0px;
    // padding: 20px;
    border-radius: 8px;
    // padding: 20px;
    // padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .sq-btn-icon {
      margin-bottom: 0.25em;
      font-size: 3rem;
    }

    .sq-btn-invert {
      display: flex;
      padding: 5px;
      margin-top: 10px;
      border-radius: 50%;

      // fill: white;
      // stroke: red;
      // color: white;

      svg {
        // stroke: #9bbf99;
        fill: #9bbf99;
        path:nth-child(2) {
          fill: #9bbf99;
        }
      }

      svg:first-child {
        fill: white;
        // stroke: #9bbf99;
        padding: 3px;
      }

      path {
        opacity: 1;
      }
    }

    .sq-btn-title {
      font-weight: bold;
      font-size: 1.3rem;
      padding-left: 10px;
      padding-right: 10px;
      text-transform: uppercase;
    }

    .sq-btn-label {
      text-transform: none;
      font-weight: 500;
      font-size: 0.9rem;
      margin-top: 0.5em;
      margin-bottom: 1.2em;
    }
  }
}

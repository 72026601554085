button {
  outline: none !important;
}

html body {
  font-family: 'century-gothic', 'arial', 'sans-serif';
}
.ai-c {
  align-items: center;
}

.jc-c {
  justify-content: center !important;
}

/* width */
::-webkit-scrollbar {
  width: 11px;
  opacity: 0;
  border-radius: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #ffffff00; */
  /* border-radius: 7px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00000077;
  border-radius: 7px;
  width: 100%;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.p-profilepic-container:hover .p-profile-pic {
  opacity: 0.1;
}

.p-profilepic-container:hover .p-profile-pic-middle {
  opacity: 1;
}

.p-profilepic-container {
  position: relative;
}

.p-profile-pic-middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.p-profile-pic-text {
  border: none;
  font-size: 14px;
  background: none;
  /* padding: 16px 32px; */
}

.p-profile-pic {
  transition: 0.5s ease;
  width: 5em;
  height: 5em;
  border-radius: 50%;
  display: bloc;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

@keyframes slidein {
  100% {
    transform: translateX(0%);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadein {
  animation: 2s linear fadein;
}

.hex-fade-left {
  z-index: 0;
  transform: translateX(-100%);
  animation: 0.3s linear slidein;
}
.hex-fade-right {
  z-index: 0;
  transform: translateX(100%);
  animation: 0.3s linear slidein;
}

.page-arrow {
  z-index: 1;
  /* height: 100vh; */
  display: flex;
  background-color: #60606055;

  justify-content: center;
  align-items: center;
  /* padding-left: 10, */
}

.hex-grid-section {
  text-align: center;
  padding: 5;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
}

.page-arrow:hover {
  opacity: 0.75;
}

.note-area::placeholder {
  /* color: #ccc; */
}

.note-area {
  padding: 10px;
  width: 100%;
  /* background-color: #444444; */
  /* color: white; */
  border: none;
  border-top: 1px solid black;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  /* min-height: 100vh; */
  min-height: 100%;
}

/* #root > div:not(.Toastify) {
  height: 100%;
} */

.st0 {
  fill: rgba(255, 0, 0, 0.5);
  stroke: #343a40;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.st1 {
  fill: none;
}
.st2 {
  font-family: 'Catamaran';
  font-weight: 800;
  fill: white;
}
.st3 {
  font-size: 24px;
}
.st4 {
  font-size: 20px;
}
.st5 {
  font-size: 20px;
  fill: black;
  text-transform: uppercase;
}
.st6 {
  font-size: 14px;
}

.st10 {
  fill: black;
}

.st10:hover text {
  fill: #007bff;
}

.st10:hover path {
  fill: black;
}

.st10:hover text {
  opacity: 0.8;
}
b .st9:hover polygon {
  opacity: 0.8;
}

.st9:hover text {
  fill: #343a40;
}

ul {
  list-style-type: none;
}

.is-selected {
  background-color: #d7e7ff;
}

.is-focused {
  border-left: 2px solid rgb(144, 192, 250);
  border-right: 2px solid rgb(144, 192, 250);
}

.p2-rate-txt,
.p2-arg-txt,
.p2-buzzer-txt {
  font-weight: 700;
}

.p2-arg-txt {
  font-size: 18px;
}

.p2-buzzer-txt {
  font-size: 28px;
}

polygon,
line {
  stroke-miterlimit: 10;
}

polygon {
  stroke-width: 3px;
  stroke: #000;
  /* fill: white; */
}

line {
  stroke-width: 15px;
  /* stroke: #f00; */
}

.p2 text {
  pointer-events: none;
}

polygon.p2-cell-rate4:hover {
  fill: #080;
}

.p2-cell-rate2:hover polygon {
  fill: #a80 !important;
}
.p2-cell-rate2:hover line {
  stroke: #a80 !important;
}

.p2-cell-rate3:hover polygon {
  fill: #aa0 !important;
}
.p2-cell-rate3:hover line {
  stroke: #aa0 !important;
}

.p2-cell-rate1:hover polygon {
  fill: #a00 !important;
}
.p2-cell-rate1:hover line {
  stroke: #a00 !important;
}

polygon.p2-cell-rate4:hover {
  fill: #080 !important;
}

.xp-bar-container {
  width: 100%;
  background-color: #ddd;
  border: 2px solid #333;
  border-radius: 5px;
}
.xp-bar {
  text-align: right;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  font-weight: 500;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.thumbnail {
  padding: 0px;
}
.panel {
  position: relative;
}
.panel > .panel-heading:after,
.panel > .panel-heading:before {
  position: absolute;
  top: 11px;
  left: -16px;
  right: 100%;
  width: 0;
  height: 0;
  display: block;
  content: ' ';
  border-color: transparent;
  border-style: solid solid outset;
  pointer-events: none;
}
.panel > .panel-heading:after {
  border-width: 7px;
  border-right-color: #f7f7f7;
  margin-top: 1px;
  margin-left: 2px;
}
.panel > .panel-heading:before {
  border-right-color: #ddd;
  border-width: 8px;
}

text {
  pointer-events: none;
  font-weight: bold;
  fill: black;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

.hexagon-cell:hover > polygon,
.hexagon-cell:active > polygon {
  opacity: 0.8;
}

.fade {
  animation: fade-in-keyframes 2s;
  opacity: 0;
}

.fade-long {
  animation: fade-in-keyframes-2 3s;
  border: 10px solid black;
  stroke: #ffffff66;
  stroke-width: 2px;

  fill: yellow;
}

@keyframes fade-in-keyframes-2 {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in-keyframes {
  from {
    opacity: 0.1;
    /* background-color: red;
    stroke: #007bff;
    stroke-width: 32px;
    border: 4px solid black; */
  }
  to {
    opacity: 1;
    /* background-color: red;
    stroke: #007bff;
    stroke-width: 14px;
    border: 4px solid black; */
    /* border: 0px; */
  }
}

.pastille {
  padding-left: 5px;
  height: 20px;
  width: 20px;
  background-color: red;
  border: 1px solid black;
  border-radius: 10px;
}

.bg {
  height: 95vh;
  background-image: url('styles/assets/bg_test_cropped.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  background-color: #cc2b80;
  /* background-image: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%); */
}

.homepage_text {
  padding: 4em;
  flex: 1;
  color: white;
}

.homepage_text_section {
  /* line-height: 2rem; */
  padding: 2em;
  margin-bottom: 15%;
  font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
}

.homepage_text_section > h1 {
  font-weight: 1000;
  font-size: 2.5rem;
}

.homepage_text_section > p {
  padding-left: 4em;
}

.homepage_text_section > p > div {
  font-size: 2rem;
  font-weight: bolder;
}

.comeon {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.homepage_hexagon_wrapper {
  flex: 1;
}

.homepage_hexagon {
  max-height: 85vh;
  height: 100%;
}

@media only screen and (min-width: 1301px) {
  .comeon {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }

  .homepage_hexagon_wrapper {
    flex: 1;
  }
}

.session-control:hover {
  cursor: move;
}

@media only screen and (max-width: 1300px) {
  .homepage_text {
    flex: 0;
    padding: 0em;
  }

  .homepage_text {
    padding: 1em;
    flex: 1;
  }

  .homepage_hexagon_wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 50%;
    transform: translate(-50%, -50%);
    /* width: 100%; */
  }

  .homepage_text_section {
    line-height: 2rem;
    padding: 0.5em;
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
  }

  .homepage_text_section > h1 {
    font-weight: 1000;
    font-size: 1.5rem;
  }

  .homepage_text_section > p {
    padding-left: 1em;
  }

  .homepage_text_section > p > div {
    font-size: 1rem;
    font-weight: bolder;
  }
}

.cls-1 {
  fill: #58508d;
}

.cls-1,
.cls-7 {
  opacity: 1;
}

.cls-2 {
  fill: #bc5090;
  opacity: 0.2;
}

.cls-3 {
  font-size: 160.65px;
  font-family: Catamaran;
}

.cls-3,
.cls-5 {
  fill: #fff;
  font-weight: 800;
}

.cls-4 {
  letter-spacing: -0.01em;
}

.cls-5 {
  font-size: 38px;
  font-family: Catamaran;
  font-style: italic;
}

.cls-6 {
  letter-spacing: 0em;
}

.cls-7 {
  fill: #d2ff0a;
}

.shadow {
  -webkit-filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 1));
  filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 1));
  /* Similar syntax to box-shadow */
}

footer {
  text-align: center;
}

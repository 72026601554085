.tut-admin-wrapper {
  padding: 1em;
  margin-top: 1.5em;

  .tut-admin-themes {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    font-size: 0.8rem;

    .tut-admin-theme {
      flex: 0 1 22%;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
      padding: 1em;
      border-radius: 8px;

      button {
        margin-top: 1em;
      }
    }
  }

  .tut-admin-videos {
    display: flex;
    gap: 2em;
    flex-wrap: wrap;
    font-size: 0.8rem !important;

    .MuiSelect-select {
      font-size: 0.8rem !important;
      padding: 6px;
    }

    .tut-admin-video {
      flex: 0 1 23%;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
      padding: 1em;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .tut-admin-video-screen {
        display: flex;
        align-items: center;
        gap: 10px;

        .screen-select {
          flex-grow: 1;
          overflow: hidden;
        }
      }

      video {
        max-height: 8em;
      }

      img {
        max-height: 8em;
      }

      button {
        margin-top: 1em;
      }
    }
  }
}

@import '../../../styles/constants.scss';

.th-session-detail {
  // display: flex;
  // flex: 1;
  background-color: $primary-faded;
  padding: 1em;
  padding-top: 0px;
  // justify-content: center;
  // flex-wrap: wrap;

  #th-sess-detail {
    display: flex;
    flex-direction: row;
    text-align: left;
    padding: 10px;
    width: 100%;
    gap: 1.5em;
    flex-wrap: wrap;

    .logo-todo {
      // width: 40%;
      // padding: 40%;
      font-size: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5% 10%;
      border-radius: 8px;
      box-sizing: border-box;
      position: relative;
    }

    .th-grades-section {
      display: flex;
      flex-wrap: wrap;
    }

    .th-grades {
      text-align: center;
      flex: 1;
      max-width: 9em;
      margin: 0 auto;

      .th-grade-progress {
        padding-left: 1.5em;
        padding-right: 1.5em;
      }

      .subtitle {
        line-height: 1;
      }

      .text-strong {
        font-size: 0.9rem;
      }
    }

    img {
      // height: 25;
      // width: 100%;
      flex: 1;
      // max-width: 30vw;
      // max-height: 40%;
      border-radius: 6px;
      margin-right: 10px;
      text-align: center;
      height: 100%;
      // width: auto;
      aspect-ratio: 16/9;
      max-height: 20vh;
      // max-height: 25%;
    }

    #th-sess-head {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
      flex-grow: 1;
      flex-wrap: wrap;

      .pastille-lightblue {
        width: fit-content;
      }

      .subtitle {
        font-size: 0.8rem;
      }
    }
  }

  @media only screen and (max-width: 468px) {
    #th-sess-detail {
      flex-direction: column;
    }
  }
}

.th-left-bar {
  flex: 1;
  background-color: $light-gray;
  border-radius: 6px;
  margin: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  // height: 100%;

  .th-grades-section {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    //justify-content: center;
  }

  .th-grades {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    text-align: center;
    max-width: 6em;
    flex: 1 1 100%;
    margin: 0 auto;
  }

  @media only screen and (min-width: 768px) {
    .th-grades {
      max-width: 8em;
      flex: 1 1 32%;
      margin-top: 30px;
    }
  }

  #th-sess-count {
    padding-top: 10%;
    font-weight: 900;
    font-size: 25px;
    color: #9b9b9b;

    font-family: 'TJ Evolette A Basic';

    #th-sess-num {
      font-size: 48px;
    }
  }
}

@import '../../styles/constants.scss';

.tc-wrapper {
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  align-self: stretch;
  background-color: $light-gray;

  .tc-close {
    position: absolute;
    right: 8px;
    top: 8px;
    font-size: 1em;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 3px;
    padding-bottom: 1.5px;
    border-radius: 50%;
    background-color: black;
    z-index: 10;
  }

  a {
    cursor: pointer;
    text-decoration: none !important;
    color: black;

    .tc-thumbnail {
      width: 100%;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      position: relative;

      .tc-thumbnail-overlay {
        position: absolute;
        background-color: #a0a0a044;

        width: 100%;
        height: 100%;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 3.5em;

        left: 0;
        top: 0;
      }

      img {
        width: 100%;
        aspect-ratio: 16/9;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
    }

    .tc-content {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;

      padding: 10px;

      .tc-title {
        font-weight: 900;
        font-size: 0.8rem;
      }

      .tc-description {
        margin-top: 0.6em;
        font-size: 0.7rem;
        padding-bottom: 1.5em;
      }
    }
  }
}

.tc-never-button {
  display: flex;
  justify-content: center;
  padding-bottom: 1em;

  .btn {
    text-transform: none;
  }
}

.tc-wrapper-a:hover .tc-close {
  opacity: 0.5;
}

.tc-wrapper-a:hover {
  div {
    opacity: 0.5 !important;
    color: black;
    text-decoration: none;
  }
}

.tc-close:hover {
  cursor: pointer;
  background-color: white;
  color: black;
  opacity: 1 !important;
}

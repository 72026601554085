@import '../../../../styles/constants.scss';

.st-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  padding: 20px;
  max-width: 70em;

  .st-scores {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    // align-items: center;
    // align-content: initial;
    // justify-content: flex-start;
    // justify-content: center;

    .st-sep {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1em;
      margin-left: 1em;
      margin-top: 6em;
      color: $dark-gray;
    }
  }

  .st-circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 200px;
    max-width: 14em;
    padding: 1.8em;

    .st-circle-title {
      margin-top: 1em;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .st-grade {
    padding: 20px 10px;
    border: 2px solid $light-gray;
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;

    .st-circle {
      padding: 0.2em;
      text-align: center;
    }

    .st-grade-item {
      flex: 1 1 30%;
      text-align: justify;
      padding: 1em;

      .st-circle {
        text-align: center;
      }
    }
  }
}

@import '../../styles/constants.scss';

.gl-wrapper {
  padding: 10px;

  .gl-top {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 7.5%;

    .gl-left {
      display: flex;
      flex-direction: column;

      .strong {
        flex: 0;
      }

      .sq-btn-container {
        flex: 1;
        margin-top: 1em;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
        height: 100%;
      }
    }

    .gl-arguments {
      margin-top: 1em !important;
      display: flex;
      // border: 2px solid $light-gray;
      border-radius: 8px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);

      .gl-arguments-left {
        flex: 3;
        display: flex;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        padding: 1em;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #a2d14644;

        div {
          flex: 0;
        }

        .gl-arguments-count {
          background-color: $primary;
          padding: 10px 12px;
          font-weight: 900;
          color: white;
          font-size: 1.5rem;
          display: flex;
          align-items: center;
          border-radius: 50%;
          margin-top: 10px;
          margin-bottom: 10px;
        }
      }

      .gl-arguments-right {
        flex: 5;
        padding: 1em;
        padding-bottom: 0.8em;
        display: flex;
        flex-direction: column;

        .btn {
          width: fit-content;
          justify-self: flex-end;
        }
      }
    }

    div {
      flex: 1;
    }
  }
}

.hexagons-content {
  flex-wrap: wrap;
  flex: 4;
  //flex-direction: column;
  height: 100%;
  // overflow: auto;
  min-width: 300px;
  margin-left: 14px;

  .hex-content-title {
    margin-top: 4.5em;
    margin-bottom: 1em;
  }

  .hexagons-container {
    display: flex;
    // flex: 1;
    // margin-top: 16px;
    margin-left: -3px;
    // min-width: 50vw;
    flex-direction: row;
    overflow: auto;
    flex-wrap: wrap;
    overflow: auto;
    max-height: 94%;
    // height: 100%;
    // flex-grow: 1;

    .empty-group {
      margin: 0 auto;
      margin-top: 1em;
    }

    .hexagons-cell {
      flex: 1 0 31%;
      // max-width: 33vw;
      margin: 5px 5px;
      margin-bottom: 10px;
      // padding: 10px;
      text-align: center;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
      border-radius: 6px;
      min-width: 280px;

      .hexagons-cell-controls {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        background-color: #f4f4f4;
        margin-top: 1em;
        padding: 2px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }

    .hexagons-cell-hoverable:hover {
      cursor: pointer;
      background-color: rgba($orange, 0.3);
    }
  }
}

.acm {
  label {
    color: $primary;
  }

  .acm-submit {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    div {
      flex: 1;
    }
  }
}

@media only screen and (min-width: 480px) {
  .acm {
    // background-color: red;
    min-width: 400px;
  }
}

/** GRID CREATION **/
.gcr-wrapper {
  padding: 20px;
  padding-top: 5px;
  display: flex;
  flex-shrink: 1;
  flex-wrap: wrap;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  gap: 20px;
  flex-grow: 1;

  .hex-phase-bubbles div {
    font-size: 16px;
    padding: 0px 8.5px;
  }

  label {
    color: $primary !important;
  }

  .gcr-left {
    flex: 6;
    padding-left: 5%;
    padding-right: 5%;
    min-width: 300px;
    flex-shrink: 1;

    .gcr-footer {
      width: 100%;
      display: flex;
      justify-content: center;

      .gcr-footer-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1em;
        width: fit-content;

        button {
          max-width: 400px;
        }
      }
    }

    .gcr-p1-top {
      margin-top: 0px;
      display: flex;
      align-items: center;

      .gcr-p1-top-left {
        width: 80px;
      }

      .gcr-p1-title {
        flex: 1;
        margin-top: -15px;
      }

      .gcr-p1-select {
        position: relative;
        float: right;
        top: -20px;
        width: 80px;

        label {
          font-size: 14px;
          font-weight: bold;
        }

        .gcr-menuitem {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }

    .gcr-p2-top {
      display: flex;
      align-items: center;

      .gcr-p2-pad {
        flex: 2;
      }

      .gcr-p2-title {
        text-align: center;
        flex: 12;
      }

      .gcr-p2-btn {
        flex: 1;
      }
    }

    .gcr-title {
      font-size: 1.1rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 0px;
    }

    .gcr-select {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex-direction: row;
      gap: 20px;
      max-width: 600px;
    }

    .gcr-hex {
      margin: 0 auto;
      max-width: 600px;
    }

    .gcr-select:first-child {
      flex: 4;
      width: 100%;
    }

    .gcr-select:nth-child(2) {
      flex: 1;
      width: 100%;
    }
  }

  .gcr-right {
    flex: 4;
    display: flex;
    flex-shrink: 1;
    flex-flow: column;
    min-width: 100px;
    // height: 100%;
    // padding: 30px;
    // background-color: $light-gray;
    border-radius: 6px;

    .gcr-description {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
    }

    .gcr-right-detail {
      // flex: 1;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      // height: 100%;
      background-color: $light-gray;
      border-radius: 6px;
      padding: 0px;
    }

    .or-option {
      display: flex;
      align-items: center;
      color: $dark-gray;
      // padding: 0px 5%;
      font-size: 1.5rem;
      padding-bottom: 10px;

      span {
        flex: 3;
        height: 1px;
        border: 1px solid $dark-gray;
      }

      div {
        flex: 1;
      }
    }

    .btn-group {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;

      div {
        flex: 1;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .gcr-wrapper {
    flex-wrap: wrap;
    .gcr-right {
      .gcr-right-detail {
        padding: 0px;
      }
    }
  }

  .gcr-p1-title {
    margin-top: 0px !important;
  }

  .gcr-p1-select {
    margin-top: 0px !important;
  }
}

.group-select {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .group-item {
    border: none;
    border-radius: 6px;
    width: 100%;
    flex: 1 1 48%;
    padding: 11px;
    background-color: #ffffff;
    font-weight: 500;
  }
}

.tf-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  // height: fit-content;
  width: fit-content;

  .tf-content {
    background-color: white;
    // margin-top: 1em;
    flex-grow: 1;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    padding: 0.75em;
    border-radius: 6px;
    display: flex;
    flex-direction: row;

    .tf-videos {
      display: flex;
      align-items: center;
      flex-grow: 1;
      gap: 1em;
      flex-wrap: wrap;

      .tc-wrapper {
        max-width: 8em;
        box-shadow: unset;

        .tc-thumbnail .tc-thumbnail-overlay {
          font-size: 2em;
        }

        .tc-content {
          padding: 0.5em;

          .tc-title {
            font-size: 0.7rem;
          }
        }
      }
    }

    .tf-button {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-left: 1em;
      gap: 0.5em;

      .btn {
        width: fit-content;
        text-transform: none;
      }
    }
  }
}

.tf-wrapper.tf-vertical {
  flex-direction: column;

  .tf-content {
    padding: 0.9em 0;
    flex-direction: column;

    .tf-videos {
      margin-bottom: 1em;
      justify-content: center;

      .tc-wrapper {
        max-width: unset;
        flex: 0 1 29%;
      }
    }

    .tf-button {
      align-items: center;
    }
  }
}

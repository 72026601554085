:root {
  --hex-red: #f24405;
  --hex-orange: #f28705;
  --hex-green: #72a603;
  --hex-dark: #307302;
}

.stp {
  width: 100%;
}

.stripes-bg {
  fill: repeating-linear-gradient(
    #e66465,
    #e66465 20px,
    #9198e5 20px,
    #9198e5 25px
  );
}

/* Hexagons */
#hexGrid {
  overflow: hidden;
  /* width: 95%; */
  /* margin: 0 auto; */
  padding: 0% 0;
  font-family: 'Raleway', sans-serif;
  font-size: 15px;
}

#hexGrid:after {
  content: '';
  display: block;
  clear: both;
}

.hex {
  position: relative;
  list-style-type: none;
  float: left;
  overflow: hidden;
  visibility: hidden;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
  -webkit-transform: rotate(-60deg) skewY(30deg) translatez(-1px);
  -ms-transform: rotate(-60deg) skewY(30deg) translatez(-1px);
  transform: rotate(-60deg) skewY(30deg) translatez(-1px);
}

.hex * {
  position: absolute;
  visibility: visible;
  outline: 1px solid transparent; /* fix for jagged edges in FF on hover transition */
}

.hexIn {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  overflow: hidden;
  -webkit-transform: skewY(-30deg) rotate(60deg);
  -ms-transform: skewY(-30deg) rotate(60deg);
  transform: skewY(-30deg) rotate(60deg);
}

/*** HEX CONTENT **********************************************************************/

.hex img {
  left: -100%;
  right: -100%;
  width: auto;
  height: 100%;
  margin: 0 auto;
}

.hex h1,
.hex p {
  margin: 0;
  width: 102%;
  left: -1%; /* prevent line on the right where background doesn't cover image */
  padding: 5%;
  box-sizing: border-box;
  /* background-color: #ccc; */
  font-weight: 700;
  -webkit-transition: -webkit-transform 0.2s ease-out, opacity 0.3s ease-out;
  transition: transform 0.2s ease-out, opacity 0.3s ease-out;
  color: white;
}

.hexRed,
.hexRed h1,
.hexRed p {
  background-color: var(--hex-red);
}
/* 
.hexRed:hover,
.hexGreen:hover,
.hexBlue:hover,
.hexDark:hover {
  background-color: rgba(130, 110, 100, 1);
} */

.hexOrange,
.hexOrange h1,
.hexOrange p {
  background-color: var(--hex-orange);
}

.hexGreen,
.hexGreen h1,
.hexGreen p {
  background-color: var(--hex-green);
}

.hexDark,
.hexDark h1,
.hexDark p {
  background-color: var(--hex-dark);
}

.hex h1 {
  bottom: 50%;
  padding-top: 50%;
  font-size: 1.5em;
  z-index: 1;
  transform: translateY(10%) translatez(-1px);
}

.hex h1:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 45%;
  width: 10%;
  text-align: center;
  border-bottom: 1px solid #fff;
}

.hex p {
  top: 50%;
  padding-bottom: 50%;
  transform: translateY(100%) translatez(-1px);
}

/*** HOVER EFFECT  **********************************************************************/
.hexIn:hover h1,
.hexIn:focus h1,
.hexIn:hover p,
.hexIn:focus p {
  transform: translateY(-10%) translatez(-1px);
  /* background-color: rgba(130, 110, 100); */
}

/*** SPACING AND SIZING *****************************************************************/

@media (min-width: 581px) {
  /* <- 2-3  hexagons per row */
  .hex {
    width: 23.5%; /* = (100-2) / 3 */
    padding-bottom: 28.29%; /* =  width / sin(60) */
  }
  .hex:nth-child(5n + 1),
  .hex:nth-child(5n + 2),
  .hex:nth-child(5n + 3),
  .hex:nth-child(5n + 4) {
    transform: translateX(0%) rotate(-60deg) skewY(30deg);
  }

  .hex:nth-child(5n + 2),
  .hex:nth-child(5n + 4) {
    margin-right: 0.5%;
    margin-left: 0.5%;
  }
  .hex:nth-child(5n + 4) {
    margin-right: 0;
  }
  .hex:nth-child(5n + 1) {
    margin-left: 0;
  }
}

@media (max-width: 580px) {
  /* <- 1-2  hexagons per row */
  .hex {
    width: 49.5%; /* = (100-1) / 2 */
    padding-bottom: 57.158%; /* =  width / sin(60) */
  }
  .hex:nth-child(3n + 1) {
    transform: translateX(50%) rotate(-60deg) skewY(30deg);
  }
  .hex:nth-child(3n + 2),
  .hex:nth-child(3n + 3) {
    margin-top: -13.423%;
    margin-bottom: -13.423%;
  }
  .hex:nth-child(3n + 1) {
    margin-left: 0.5%;
  }
  .hex:nth-child(3n + 3) {
    margin-left: 1%;
  }
  .hex:nth-child(3n + 2),
  .hex:nth-child(3n + 4) {
    clear: left;
  }
}

@media (max-width: 400px) {
  #hexGrid {
    font-size: 13px;
  }
}

.hexContainer {
  width: 60vw;
}

.rejoindreMargin {
  margin-top: 1em !important;
}

@media (min-width: 1501px) {
  .hexContainer {
    padding-left: 5%;
    width: 60vw;
  }
}

@media (max-width: 1500px) and (min-width: 1201px) {
  .hexContainer {
    width: 60vw;
    padding-left: 5%;
  }
}

@media (max-width: 1200px) and (min-width: 958px) {
  .hexContainer {
    width: 60vw;
  }

  .rejoindreMargin {
    margin-top: 5em !important;
  }
}

@media (max-width: 958px) {
  .hexContainer {
    width: 100vw;
  }
}

@media (max-width: 580px) {
  .hexContainer {
    width: 65vw;
  }
}

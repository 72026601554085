@import '../../../styles/constants.scss';

.slist-content-teacher {
  max-width: 50em;
  margin: 0 auto;
  flex: 1;
}

.slist-container {
  margin: 7px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  .slist-card {
    display: flex;
    flex-direction: column;
    display: flex;
    // align-items: center;
    gap: 5px;
    width: 100%;
    // padding: 10px;
    // margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    color: black;
    gap: 5px;

    .slist-card-warning {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 10px;

      span {
        font-size: 10px;
        line-height: 1.2;
        font-weight: 900;
        color: $orange;
      }
    }

    .slist-card-inner {
      padding: 10px;
      display: flex;
      align-items: center;
      width: 100%;
      flex-grow: 1;
    }

    .logo-todo {
      height: 75px;
      width: 112px;
      min-width: 112px;
      margin-right: 10px;
      margin-left: 8px;

      border-radius: 6px;
    }

    .logo-todo-ico {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
    }

    @media only screen and (max-width: 768px) {
      .logo-todo {
        height: 75px;
        width: 112px;
      }
    }

    .slist-card-content {
      flex-wrap: wrap;
      flex-grow: 1;
    }

    @media only screen and (max-width: 768px) {
      .slist-card-controls {
        padding-top: 10px;
      }
    }

    .slist-card-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;
      margin-top: 5px;
      padding: 5px 10px 5px 10px;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      background-color: $light-gray;
      width: 100%;
    }

    .slist-card-controls-bg {
      background-color: #f4f4f4;
      padding: 5px;
      display: flex;
    }

    .slist-card-controls {
      display: flex;
      align-items: center;
      // background-color: #f4f4f4;

      button,
      a {
        margin: 5px;
      }

      .slist-weval {
        text-align: center;
        color: $accent-gray;
        font-size: 0.8rem;
      }
    }

    span {
      font-size: 11px;
    }

    .pastille-lightblue {
      margin-right: 10px;
    }

    .subtitle {
      font-size: 13px;
    }
  }

  .slist-clickable:hover {
    background-color: rgba($orange, 0.25);
    text-decoration: none;
    // color: white !important;
    cursor: pointer;

    .subtitle {
      // color: white;
    }
    // opacity: 0.5;
  }

  .slist-session-type {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;

    @media only screen and (max-width: 768px) {
      button {
        font-size: 0.6rem;
      }
    }

    button {
      margin: 5px 10px 5px 0px;
    }
  }

  .slist-filter-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    select {
      flex: 1 1 49%;
      width: 100%;
    }
    .slist-bi {
      flex: 1 1 49%;
      width: 100%;
      display: flex;
      align-items: center;

      span {
        padding-left: 10px;
        padding-right: 10px;
        background-color: #f4f4f4;
        color: #9b9b9b;
        font-weight: 900;
        font-size: 25px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
}

.in-person-wrapper {
  width: fit-content;

  .in-person-icons {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    margin-top: 1.5em;

    .deposit-card {
      flex: 1 1 45%;
      margin-top: 0;
      margin-bottom: 0.75em;
      padding-bottom: 0.5em;
      padding-left: 1.5em;
      padding-right: 1.5em;
      width: 100%;

      .deposit-card-title {
        white-space: nowrap;
      }
    }
  }
}

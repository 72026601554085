@import '../../styles/constants.scss';

.sc-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 10px;
  height: 100%;

  .sc-title {
    font-size: 1.1rem;
    font-weight: bolder;
    text-align: left;
  }

  h2 {
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 20px;
  }

  .sc-title {
    margin-top: 1.5em;
  }

  .creation-existing {
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      max-width: 30vw;
    }
  }

  @media screen and (max-width: 480px) {
    .creation-existing {
      div {
        max-width: none;
      }
    }
  }

  .creation-options {
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 1.5em;
    // margin: ();
    margin-top: 1.5em;
  }

  .creation-option {
    flex: 1;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;

    .subtitle {
      margin-top: 20px;
    }
  }

  .or-option {
    display: flex;
    align-items: center;
    color: $dark-gray;
    // padding: 0px 10%;
    padding-top: 2em;
    padding-bottom: 2em;

    span {
      flex: 5;
      height: 1px;
      border: 1px solid $dark-gray;
    }

    div {
      flex: 1;
    }
  }
}

@import './constants.scss';

@font-face {
  font-family: 'TJ Evolette A Basic';
  src: local('TJ Evolette A Basic'), url('./fonts/TJEvoletteABasic-Normal.otf') format('opentype');
}

@font-face {
  font-family: 'century-gothic';
  src: local('century-gothic'), url('./fonts//CenturyGothic.otf') format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

.MuiCollapse-wrapperInner {
  // display: flex;
  // width: 100%;
}

button,
button:focus {
  outline-color: $gray !important;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

input,
textarea {
  background-color: $light-gray;
  border: none;
  border-radius: 3px;
  padding: 6px 7px;
  width: 100%;
}

.small-text {
  font-size: 13px;
}

.no-break {
  overflow-wrap: normal;
}

.capitalize {
  text-transform: capitalize !important;
}

@media only screen and (max-width: 640px) {
  .no-break {
    font-size: 14px !important;
  }
}

.vertical-sep {
  border: 1px solid;
  color: $dark-gray !important;
  height: 25px;
}

.fullwidth {
  width: 100%;
}

.primary {
  color: $primary;
}

.red {
  color: $red;
}

.orange {
  color: $orange;
}

.darkblue {
  color: $dark-blue;
}

.subtitle {
  font-size: 0.9rem;
  color: $dark-gray !important;
}

.red {
  color: $red !important;
}

.accent-gray {
  color: $accent-gray;
}

.nopad {
  padding: 0px;
}

.btn {
  color: white;
  font-weight: 900;
  padding: 10px 20px;
  font-size: 18px;
  text-transform: uppercase;
  outline: none !important;
}

.btn-primary,
.btn-primary.disabled {
  background-color: $primary;
  border-color: $primary;
}

.btn-primary:disabled,
.btn-primary:disabled:hover {
  background-color: $primary !important;
  border-color: $primary !important;
}

.btn-primary:focus,
.btn-primary:hover {
  background-color: $orange !important;
  border-color: $orange !important;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn:focus {
  // outline: 2px solid blue !important;
}

.btn:hover {
  opacity: 0.75;
}

.btn-text {
  border: none !important;
  background-color: none !important;
  color: $primary;
  padding: 0px !important;
}

.dark-gray {
  color: $dark-gray;
}

.btn.small {
  font-size: 13px;
  padding: 6px 10px;
}

.btn-outline {
  color: $dark-gray;
  background-color: none !important;
  border-color: $dark-gray;
}

.no-focus:focus {
  background-color: $primary !important;
  border-color: $primary !important;
}

.btn-outline:hover,
.btn-outline:focus,
.btn-icon:hover {
  background-color: $accent-gray;
  border-color: $accent-gray;
  color: white;
}

.text-strong {
  font-size: 18px;
  text-transform: uppercase;
}

.icon-btn:hover,
.icon-btn:focus {
  opacity: 0.75;
  outline: none !important;
  box-shadow: none !important;

  .ico {
    background-color: $accent-gray;
    border-color: $accent-gray;
    color: white;
  }

  .label {
    color: $accent-gray;
  }
}

.btn-icon {
  background-color: #ffffff00;
  color: #9b9b9b;
  text-transform: uppercase;
  padding: 0px;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  border: 1px solid #9b9b9b;
  padding: 3px 3px;
  border-radius: 50%;
}

.icon-btn {
  background-color: #ffffff00;
  color: #9b9b9b;
  text-transform: uppercase;
  outline: none !important;
  border: none;
  padding: 0px;
  gap: 15px;
  display: flex;
  align-items: center;
  font-size: 1.1rem;

  .ico {
    display: flex;
    align-items: center;
    border: 1px solid #9b9b9b;
    padding: 3px 3px;
    border-radius: 50%;
  }
}

.pastille-lightblue {
  color: white;
  font-weight: bolder;
  background-color: #97b5bf;
  border-radius: 6px;
  padding: 4px 10px 4px 10px;
  font-size: 11px;
}

.round-pastille {
  border-radius: 50%;
  width: 200px;
  display: flex;
  text-align: center;
  height: 200px;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-size: 25px;
  text-transform: uppercase;
  border: none;
}

textarea {
  border-radius: 6px;
}

.bg-lightblue {
  color: white;
  background-color: $light-blue;
}

.bg-darkblue {
  color: white;
  background-color: #b997b2;
}

.lightblue {
  color: $light-blue;
  font-size: 12px;
  margin: 0;
  margin-bottom: 5px;
}

.sess-info {
  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: 12px;
}

.MuiAutocomplete-root {
  background-color: white !important;
  padding: 5px;
  padding-bottom: 3px;
  padding-left: 14px;
  border-radius: 6px;
  font-weight: 900;

  .MuiAutocomplete-input {
    font-weight: bold;
  }
}

.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  flex-grow: 1;

  .content-sidebar {
    flex: 3;

    // max-width: 35vw;
    // display: block;
    // overflow: auto;
    // height: 100%;
    width: 100%;

    // height: fit-content;
    word-wrap: break-word;
    background-color: $light-gray;
    border-radius: 6px;
    // text-align: center;
    // justify-content: center;
    // min-width: 300px;
    margin-bottom: 10px;
    // max-width: 40vw;
    // padding: 1.5em;

    display: flex;
    flex-flow: column;

    .content-title {
      font-family: 'TJ Evolette A Basic', 'century-gothic', 'arial' !important;
      padding-top: 10%;
      font-weight: 900;
      color: #9b9b9b;
      font-size: 25px;
      text-transform: uppercase;

      .content-title-big {
        font-size: 48px;
      }
    }
  }

  .content-data {
    flex: 7;
    // height: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .content-wrapper {
    // padding: 20px;

    .content-sidebar {
      min-height: 100%;
      margin-right: 10px;
      margin-bottom: 0px;
      width: 100%;
      max-width: 35vw;
      // word-wrap: break-word;
    }
  }

  .body-wrapper {
    padding: 1em;
    width: 1200px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 80%;
  }
}

@media only screen and (min-width: 1020px) {
  .body-wrapper {
    padding: 1em;

    display: flex;
    flex-direction: column;
  }
}

.unset-body-wrapper {
  width: unset;
  max-width: unset;
}

.hexagon-cell:hover {
  polygon {
    fill: #c7c7c7;
  }

  opacity: 0.75;
  // filter: grayscale(0.25);
}

.hexagon-cell-no-filter:hover {
  opacity: 0.8;
  filter: none !important;
}

.vr {
  height: 3em;
  border-left: 1px solid #979797;
  margin-left: 0.8em;
  margin-right: 1em;
}

.h1-strong {
  color: $pure-black;
  font-size: 1.7rem;
  text-transform: uppercase;
  font-weight: 900;
  font-family: 'TJ Evolette A Basic', 'century-gothic', 'arial';
}

.strong {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}

.prestation-title {
  display: flex;
  align-items: center;
  // justify-content: center;
  gap: 10px;

  .p {
    transform: scalaeX(-1);
    background-color: #ced8b1;
    border-radius: 50%;
    color: white;
    padding: 7px;
  }

  h2 {
    margin-bottom: 0;
    color: $accent-gray;
  }
}

.box-shadow {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}

.round-icon {
  font-size: 1.3em;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: $primary;
  width: fit-content;
  width: -moz-max-content; /* Firefox/Gecko */
  padding: 9px 7px 6px 8px;
  margin: 0 auto;
}

.btn-no-effect {
  text-transform: none;
  border-radius: 6px;
}

.btn-no-effect:hover {
  background-color: #ffffff00 !important;
}

.hex-group-btn {
  padding: 4px 12px;
  border-radius: 7px;
  margin-bottom: 16px;
  margin-right: 12px;
  text-transform: capitalize;
}

.clickable:hover {
  cursor: pointer;
  opacity: 0.75;
}

.deposit-card,
.deposit-card-no-hover {
  width: 100%;
  margin: 2em 0;
  // max-width: 40%px;
  padding: 10px 0px;
  padding-bottom: 2em;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .deposit-card-title {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  .deposit-card-icon {
    padding: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background-color: $primary;
    color: white;
    border-radius: 50%;
    width: fit-content;
    width: -moz-max-content;
    font-size: 2rem;
    margin-bottom: 20px;
    margin-top: 5px;
  }
}

.deposit-card:hover {
  cursor: pointer;
  // color: white;
  background-color: rgba($orange, 0.3);
}

.deposit-card-no-hover:hover {
  background-color: none !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.tut-context-floating {
  max-width: 18em;
  position: fixed;

  right: 1.5em;
  bottom: 1.5em;
}

@media only screen and (max-width: 1200px) {
  .tut-context-floating {
    max-width: 11em;
  }
}

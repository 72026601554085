$primary: #a2d146 !default;
$primary-faded: #a2d14622 !default;
$light-blue: #45baba !default;
$dark-blue: #2d6cb5 !default;
$orange: #f49700 !default;
$gray: #393939 !default;
$light-gray: #f4f4f4 !default;
$dark-gray: #a0a0a0 !default;
$pure-black: #1e1e1e !default;
$red: #e21b3c !default;
$accent-gray: #9b9b9b !default;
$bg-primary: #a2d14622 !default;

@import '../../styles/constants.scss';

.scl-search {
  width: 100%;

  display: flex;
  align-items: center;

  span {
    padding-left: 10px;
    padding-right: 10px;
    background-color: #f4f4f4;
    color: #9b9b9b;
    font-weight: 900;
    font-size: 25px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
.scl-wrapper {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  padding: 10px;

  .scl-card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    gap: 10px;
    padding: 10px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    margin-top: 10px;
    margin-bottom: 10px;

    color: $accent-gray;

    .scl-card-title {
      color: black;
      margin-left: 5px;
      flex-grow: 1;
      text-align: left;
    }
  }

  .scl-ico {
    margin: 0 auto;
    color: $primary;
    font-size: 6rem;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 5px solid $primary;
    width: fit-content;
    padding: 30px;
  }

  .scl-card:hover {
    cursor: pointer;
    background-color: rgba($orange, 0.25);
  }
}

@import '../../styles/constants.scss';

.gh-container {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  max-width: 400px;
  padding: 10px;
  padding-top: 20px;

  h2 {
    font-weight: 900;
    font-size: 25px;
  }

  .gh-lc {
    flex-grow: 1;
  }

  .gh-bigico {
    margin: 0 auto;
    color: $primary;
    font-size: 6rem;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 5px solid $primary;
    width: fit-content; /* Safari/WebKit uses a non-standard name */
    width: -moz-max-content; /* Firefox/Gecko */
    padding: 30px;
    margin-bottom: 20px;
  }

  .gh-ico {
    color: $primary;
    margin-right: 10px;
  }

  .gh-btn {
    width: 100%;
    background-color: white;
    border: none;
    border-radius: 5px;
    margin: 10px 0px 00px 00px;
    padding: 8px 12px;
    box-shadow: 0px 0px 2px gray;
    display: flex;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
  }

  button:hover {
    background-color: $orange;
    color: white !important;

    .subtitle {
      color: white;
    }
  }
}

@import '../../styles/constants.scss';

.prep-h2 {
  color: $accent-gray;
  margin-bottom: 1.5em;
}

.prep-selection {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2%;
  // justify-content: center;
  width: fit-content;

  a {
    color: $primary;
    text-decoration: underline;
  }

  .prep-selection-title {
    font-size: 2rem;
    color: $pure-black;
    text-transform: uppercase;
  }
}

.hex-p2 {
  // flex: 1;
  margin: 0 auto;
  overflow-x: auto;
  width: 100%;
  height: 100%;
  align-items: unset;
  // padding: 20px;

  table {
    min-width: 500px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    flex-direction: column;
    width: 100%;

    tr {
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      width: 100%;
      justify-content: space-evenly;
      padding: 3px;
      font-size: 12px;
      border-bottom: 1px solid #c3c3c3;
      align-items: center;

      .p2-choice {
        text-align: center;
      }

      td {
        flex: 1;
        font-size: 14px;
        font-weight: 900;
        color: #1e1e1e;
        text-align: center;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          /* IE10+ CSS */
          display: flex;
        }

        flex-direction: column;
      }

      th {
        flex: 1;
        font-size: 16px;
        text-transform: uppercase;
        text-align: center;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          /* IE10+ CSS */
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .match-container {
    font-size: 11px;
    .round-match {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin: 0 auto;
    }
  }
}

@import '../../styles/constants.scss';

.home-header {
  padding: 2em;
  text-align: center;
  background-color: $primary-faded;

  .home-img {
    max-width: 5em;
  }

  h2 {
    margin-top: 0.7em;
    margin-bottom: 0.4em;
    font-size: 1.4rem;
  }

  .subtitle {
    font-size: 0.9rem;
    color: $gray;
  }
}

.home-content {
  margin-top: 2em;

  .strong {
    font-size: 1.3rem;
  }

  .home-actions {
    margin-top: 1em;
    display: flex;
    gap: 1.5em;

    .sq-btn-container {
      height: unset !important;
    }
  }

  @media screen and (max-width: 480px) {
    .home-actions {
      flex-wrap: wrap;
    }
  }

  .sq-btn-container {
    // height: unset;
  }

  .sq-btn-icon {
    font-size: 2.5rem;
    padding: 20px;
  }

  .btn-square {
    // padding-bottom: 40px;
  }
}

.onboarding-skip {
  position: absolute;
  top: 2em;
  right: 2em;
  opacity: 0.6;
  border-radius: 8px;
  background-color: $light-gray !important;
  color: #333333 !important;
}

.onboarding-skip:hover {
  background-color: $dark-gray !important;
}

.onboarding {
  display: flex;
  align-items: center;
  justify-content: center;

  .hex-phase-bubbles div {
    font-size: 16px;
    padding: 0px 8px;
  }

  .onboarding-step {
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-width: 40em;
    text-align: center;

    .sq-btn-container {
      height: unset;
    }

    .onboarding-hexagon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 300px;
    }

    .hex-phase-bubbles {
      margin-top: 1em;
      margin-bottom: -0.75em;
    }

    .onboarding-exercises {
      display: flex;
      gap: 1em;
      width: 100%;
    }

    .onboarding-next {
      min-width: 10em;
    }

    .onboarding-video {
      max-width: 15em;
      text-align: left;
      margin-top: 1em;
      margin-bottom: 1em;
    }

    .onboarding-dummy-video {
      // position: relative;
      img {
        opacity: 0.3;
      }

      div {
        font-weight: 900;
        font-size: 1.2rem;
        margin-top: -20px;
        opacity: 0.75;
        margin-bottom: 20px;
        // position: absolute;

        // top: 33%;
        // left: 33%;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .onboarding-step {
      max-width: 90vw;
    }
  }
}

/**
* ====== PENDING ACTIVITIES ======
**/
.bottom-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 7%;
}

@media screen and (max-width: 768px) {
  .bottom-wrapper {
    display: block;
  }
}

.tf-wrapper {
  min-width: 16em !important;
}

.pa-wrapper {
  flex: 1;
  margin-top: 7%;
  min-width: 16em;

  .pa-empty {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    padding: 1.5em;
    margin-top: 1em;
  }

  .pa-list {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    margin-top: 1em;
    max-height: 23em;
    overflow: auto;

    .pa-list-inner {
      padding: 0px 1.4em;
      padding-bottom: 0;

      .par-wrapper {
        display: flex;
        gap: 10px;
        padding: 15px 0px;
        border-bottom: 2px solid #e9e9e9;

        .par-icon {
          display: flex;
          align-items: center;
          justify-content: center;

          .par-icon-wrapper {
            color: white;
            font-size: 1.4rem;
            border-radius: 50%;
            padding: 8px 8px;

            display: flex;
            align-items: center;
            justify-content: center;
          }
          // padding: 14px 0.7rem;
        }

        .par-content {
          flex-grow: 1;

          .par-content-title {
            color: black;
            font-weight: 900;
            font-size: 0.85rem;
          }

          .subtitle {
            font-size: 0.75rem;
          }
        }

        .par-arrow {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $dark-gray !important;
          opacity: 0.5;
        }
      }
    }

    .par-wrapper:hover {
      text-decoration: none !important;
      opacity: 0.5;
    }
  }
}

@import '../../styles/constants.scss';

.tutos-header {
  padding-left: 3.5em;
  padding-bottom: 1em;
  background-color: $bg-primary;
  color: $accent-gray;
}

.tutos-wrapper {
  padding: 1em;
  padding-top: 0.5em;
  align-items: center;

  .tutos-card-wrapper {
    max-width: 84%;

    .tc-wrapper {
      box-shadow: unset;
    }
  }

  .tuts-theme-title {
    font-weight: 900;
    margin-top: 2.5em;
    font-size: 1.1rem;
    margin-bottom: -10px;
  }

  .tuts-theme {
    display: flex;
    align-items: center;
    gap: 1.5em;
    margin-top: 1em;
    // width: 80%;
    flex-wrap: wrap;

    .tc-wrapper {
      align-self: stretch;
      margin-top: 0.8em;
      flex: 0 1 31%;
    }
  }

  @media only screen and (max-width: 768px) {
    .tutos-card-wrapper {
      max-width: unset;
    }

    .tuts-theme .tc-wrapper {
      flex: unset;
    }
  }

  .slist-bi {
    flex: 1 1 49%;

    margin-top: 2em;
    max-width: 25em;
    display: flex;
    align-items: center;

    span {
      padding-left: 10px;
      padding-right: 10px;
      background-color: #f4f4f4;
      color: #9b9b9b;
      font-weight: 900;
      font-size: 25px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}

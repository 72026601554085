
video {
  max-width: 100%;
}

.ctrls {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  button: {
    width: 100%;
    flex: 1;
  }
}
@import '../../styles/constants.scss';

.hex-p2 {
  // flex: 1;
  width: 100%;
  height: 100%;
  align-items: unset;
  padding: 20px;

  table {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    flex-direction: column;
    width: 100%;

    tr {
      text-align: left;
      display: flex;
      flex: 1;
      width: 100%;
      justify-content: space-evenly;
      padding: 0px !important;
      font-size: 12px;
      border-bottom: 1px solid #c3c3c3;
      align-items: center;

      .p2-choice {
        text-align: center;
      }

      td {
        flex: 1;
        font-size: 14px;
        font-weight: 900;
        color: #1e1e1e;
        text-align: left;
      }

      th {
        font-size: 1rem;
        flex: 1;
        text-align: center;
      }
    }
  }
}

.hex-phase-bubbles {
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    color: white;
    background-color: #d8d8d8;
    padding: 0px 6px;

    font-size: 12px;
    margin: 5px;
    font-weight: bold;
    border-radius: 50%;
  }

  .hex-bubble-current {
    background-color: $pure-black;
  }
}

@import '../../styles/constants.scss';

.pr-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;

  .pr-title {
    margin-top: 20px;
    // width: 65%;
  }
}

.tab-selector {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid $dark-gray;

  .tab-item {
    flex: 1;
    text-align: center;
    padding: 10px !important;
    font-weight: 500;
    font-size: 1rem;
    text-transform: uppercase;
    color: black;
  }

  .tab-item-selected {
    color: $primary;
    border-bottom: 3px solid $primary !important;
    border-radius: 0px;
  }
}

.sdt-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  padding: 20px;
  max-width: 70em;

  .sdt-doug-desc {
    display: flex;
    // align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 50em;
    text-align: left;
    margin: 0 auto;
    margin-top: 3em;
    margin-bottom: 6em;

    .sdtdg-item {
      flex: 1 1 33%;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 14px;

      .sdtdg-title {
        display: flex;
        align-items: center;
        font-weight: 900;
        font-size: 16px;

        .sdtdg-pastille {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
  }

  .chart-container {
    // margin: 0 auto;
    width: 100%;
    min-width: 50vw;

    .cc-chart {
      width: 100%;
      height: 20em;
    }

    .cc-bars {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .cc-arrow {
      display: flex;
      align-items: center;
      background-color: #9b9b9b;
      color: white;
      font-size: 16px;
      padding: 5px;
    }

    .cc-bubbles {
      display: flex;
      gap: 10px;
      margin-top: 10px;
      justify-content: center;

      .cc-bubble {
        border-radius: 50%;
        width: 12px;
        height: 12px;
        background-color: #d8d8d8;
      }
    }
  }
}

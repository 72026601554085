@import '../../styles/constants.scss';

.nvbar-container {
  width: 100%;
  text-align: left;
  padding: 20px;
  // padding-bottom: 0px;
  padding-bottom: 13px;
  align-items: center;
  background-color: $bg-primary;

  .nvbar-content {
    display: flex;
    align-items: center;

    button,
    a {
      font-size: 25px;
      display: flex;
      align-items: center;
    }

    h1 {
      padding: 0px;
      color: $pure-black;
      font-size: 25px;
      margin: 0;
      margin-left: 10px;
      font-weight: 900;
      text-transform: uppercase;
    }
  }

  hr {
    // height: 0px;
    background-color: #9b9b9b;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@import '../../styles/constants.scss';

@media only screen and (min-width: 480px) {
  .comment-dialog {
    width: 20em;
  }

  .evr-wrapper {
    .content-sidebar {
      min-width: 480px;
      width: 100%;
    }
  }
}

.evr-wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  height: 100%;

  .evr-row-sep {
    width: 100%;
    margin-top: 5px;
    // margin-bottom: 8px;
    border-bottom: 1px solid #cecece;
  }

  .evr-histo-comment {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 11px;
    margin-left: 55px;
    margin-top: 5px;

    :first-child {
      flex-grow: 1;
    }

    button {
      font-size: 12px;
      // padding: 3px;
    }
  }

  .content-sidebar {
    min-width: px;
  }
  // overflow: hidden;

  .evr-left {
    height: 100%;
    flex: 2;
    // max-height: 100vh;
    background-color: $light-gray;
  }

  .evr-col-shrink {
    max-height: 25vh;
    overflow: auto;
  }

  .evr-audio {
    background-color: #ced8b1;
    color: white;
    font-size: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.8em;
    padding-bottom: 0.8em;
    // padding: 2.5em;
  }

  .evr-videoframe {
    position: relative;
    padding-top: 56.25%;
    min-width: 480px;
    // max-width: 35vw;

    // width: 33%;
  }

  .evr-histo-mobile {
    // max-height: 100;
    overflow: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 480px) {
    .content-sidebar {
      min-width: 300px !important;
    }

    .evr-audio {
      // display: none;
    }

    .evr-histo-mobile {
      // max-height: 35vh;
      // overflow: auto;
      // background-color: black;
      // display: none;
    }

    .evr-videoframe {
      position: relative;
      padding-top: 56.25%;
      min-width: 300px;
    }
  }

  .evr-content {
    margin-top: 1.5rem;
    // max-width: 100%;
    width: 100%;
    // flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    // padding: 20px;
  }

  .evr-hexs {
    flex: 5;
    max-height: 90vh;
    // min-width: 50%;
    // min-width: 900px;
    background-color: red;
    width: 100%;
    // overflow: scroll;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  @media only screen and (max-width: 768px) {
    .evr-histo-mobile {
      // display: none;
      .evr-histo {
        max-height: 13vh;
      }
    }
  }

  .evr-histo {
    flex: 1;

    overflow: auto;
    flex-grow: 1;
    margin-top: -5px;
    // max-height: 35vh;
    // max-width: 25vw;
    word-wrap: break-word;
    // padding-left: 20px;
    // padding-right: 20px;
    padding-bottom: 20px;
    transform: translateZ(0);

    .evr-histo-row-wrap {
      padding-left: 1.1rem;
      margin-left: 10px;
      margin-right: 1.5rem;
      margin-bottom: 2px;
      margin-top: 2px;
      // margin-bottom: 5px;
      // padding-top: 10px;
      // background-color: white;
    }

    .evr-histo-flashing {
      background-color: white;
      position: relative;
      border-radius: 10px;
      z-index: 3;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
    }

    .evr-histo-mask {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #1e1e1e66;
      z-index: 2;

      .evr-histo-mask-fixed {
        position: fixed;
      }
    }

    .evr-histo-row {
      display: flex;
      align-items: center;
      padding-top: 10px;
      // margin-bottom: 4px;

      .evr-histo-pastille {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .evr-histo-time {
        font-size: 11px;
        margin-right: 10px;
      }

      .evr-histo-name {
        display: flex;
        flex-grow: 1;

        button {
          font-size: 12px;
        }

        .evr-histo-name-title {
          font-size: 14px;
          font-weight: 700;
          flex-grow: 1;
        }
      }
    }
  }

  .evr-timer {
    background: #3b3b3b;
    text-align: center;
    font-size: 3rem;
    color: white;
    padding-top: 17.5%;
    padding-bottom: 17.5%;
  }

  .evr-uploading {
    background: #3b3b3b;
    text-align: center;
    color: #fff6;

    .evr-uploading-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3.5rem;
    }

    .evr-uploading-text {
      font-size: 1rem;
      margin-top: 0.5em;
      font-weight: bold;
    }

    padding-top: 15%;
    padding-bottom: 15%;
  }
}

.hex-title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: black;
  color: $pure-black;
  font-weight: 900;
  font-family: 'Tivolette', 'century-gothic';
  font-size: 35px;

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

@media only screen and (max-height: 500px) {
  .start-dialog {
    .start-dialog-progress {
      width: 5em !important;
    }

    h2 {
      margin-top: 0.5em !important;
    }
  }
}

.start-dialog {
  text-align: center;
  align-items: center;
  justify-content: center;

  .start-dialog-progress {
    width: 10em;
    margin: 0 auto;
    // margin-bottom: 20px;
  }

  h2 {
    margin-top: 3em;
    text-transform: uppercase;
  }

  button {
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@import '../../styles/constants.scss';

input {
  background-color: $light-gray;
  border: none;
  border-radius: 6px;
  padding: 6px 7px;
}

.input-icon {
  background-color: $light-gray;
  display: flex;
  align-items: center;
  border-radius: 6px;

  .icon {
    color: $dark-gray;
    font-size: 1.1rem;
    margin-right: 4px;
  }
}

@import '../../../../styles/constants.scss';

.st1-section {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  height: 100%;

  .st1-video {
    flex: 2;
    height: 100vh;
    height: 100%;
    background-color: $light-gray;
    text-align: center;
  }
}

.st1-videoframe {
  position: relative;
  padding-top: 56.25%;
  min-width: 480px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .st1-videoframe {
    /* IE10+ CSS */
    position: relative;
    padding-top: 56.25%;
    min-width: 0px !important;
  }
}

@media screen and (max-width: 480px) {
  .evr-videoframe {
    position: relative;
    padding-top: 56.25%;
    min-width: 300px;
  }
}

.no-style {
  text-align: left;
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 3px;
  border: none;
}

.st1-wrapper {
  // flex: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  // padding: 20px;
  max-width: 50em;

  .st1-table {
    width: 100%;
    overflow: scroll;
  }
}

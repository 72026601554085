@import '../../styles/constants.scss';

.tv-desc {
  background-color: $bg-primary;
  padding-left: 3.4em;
  padding-bottom: 1em;
  color: $accent-gray;
}

.tv-wrapper {
  padding: 5%;
  video {
    margin-top: 1.2em;
    aspect-ratio: 16/9;
  }
}

@import '../../styles/constants';

.fcrea-wrapper {
  flex: 1;
  padding: 10px;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 0 auto;

  form {
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    max-width: 60em;
  }

  .imported-file {
    display: flex;
    align-items: center;
    gap: 20px;
    // justify-content: center;
  }

  .fcrea-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 30px;

    div {
      flex: 1 1 50%;
    }

    @media only screen and (min-width: 768px) {
      div {
        flex: 1;
      }
    }
  }

  .fcrea-footer {
    margin-top: 2.5%;
    text-align: center;

    button {
      margin-top: 2.5%;
      min-width: 30%;
    }
  }

  .fcrea-dlink {
    display: flex;
    width: 100%;
    flex: 1;
    // margin-top: 10px;
    gap: 20px;

    img {
      width: 100%;
      // width: 50px;
      // width: 108px;
      // height: 60px;
      // margin-right: 20px;
      // padding-top: 5px;
    }

    .fcrea-dlink-input {
      width: 100%;
      height: 100%;
      flex: 5;
    }
  }

  .fcrea-participants {
    display: flex;
    flex-wrap: wrap;

    .fcrea-participant {
      border: none;
      background-color: white;
      flex: 1 1 50%;
      padding: 10px;
      color: $pure-black;

      .img-input {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        cursor: pointer;
        background-color: #f4f4f4;
        display: flex;
        align-items: center;
        justify-content: center;

        div {
          background-color: $primary;
          color: white;
          font-weight: bold;
          padding: 5px;
          border-radius: 6px;
        }
      }

      input[type='file'] {
        display: none;
      }

      .fcrea-participant-empty {
        display: flex;
        flex-direction: column !important;
        align-items: center;
        justify-content: center;
      }

      .fcrea-participant-inner {
        align-items: center;

        padding: 10px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
        border-radius: 6px;
        height: 100%;

        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        flex-direction: row;

        .img-button {
          background-color: black;
          // border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 16px;
          position: absolute;
          font-weight: 900;
          top: 5px;
          right: 5px;
        }
      }

      b {
        font-weight: 900;
        font-size: 22px;
      }
    }

    .fcrea-plus {
      font-size: 3.4em;
    }
  }

  @media only screen and (max-width: 768px) {
    .fcrea-participants {
      .fcrea-participant {
        flex: 1 1 51%;
      }
    }
  }
}

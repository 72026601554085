.alist-filter-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;

  select {
    flex: 1 1 49%;
    width: 100%;
  }

  .alist-bi {
    flex: 1 1 49%;
    width: 100%;
    display: flex;
    align-items: center;

    span {
      padding-left: 10px;
      padding-right: 10px;
      background-color: #f4f4f4;
      color: #9b9b9b;
      font-weight: 900;
      font-size: 25px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.args-content {
  padding: 10px;
  padding-left: 6%;
  padding-right: 6%;
  flex-wrap: wrap;
  flex-wrap: wrap;
  flex: 4;
  flex-direction: column;
  height: unset;
  overflow: auto;
  min-width: 300px;

  .arg-card {
    padding: 10px;
    padding-left: 20px;

    margin: 10px;
    display: flex;

    flex-direction: row;
    gap: 20px;

    .arg-card-head {
      flex-grow: 1;
    }

    .arg-card-controls {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }

    .pastille-lightblue {
      width: fit-content;
    }
  }
}

@media only screen and (min-width: 1200px) {
  padding: 10px;
}
